@import url("https://fonts.googleapis.com/css?family=Oswald:400,500,700");
@font-face {
  font-family: "BrandonGrotesque-Regular";
  src: url("./assets/fonts/BrandonGrotesque-Regular.eot");
  src: url("./assets/fonts/BrandonGrotesque-Regular.woff2") format("woff2"), url("./assets/fonts/BrandonGrotesque-Regular.woff") format("woff"), url("./assets/fonts/BrandonGrotesque-Regular.ttf") format("truetype"), url("./assets/fonts/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular") format("svg"), url("./assets/fonts/BrandonGrotesque-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #e5dfd6;
  font-family: "BrandonGrotesque-Regular";
  position: relative; }

.loader {
  font-size: 30px;
  padding: 80px 0; }

.header {
  background: url("./assets/images/background.jpg");
  background-position: center;
  background-size: cover;
  height: 540px; }
  .header .logo {
    padding: 50px 0 30px; }

.info {
  height: 100px;
  background: #ffffff; }
  .info .event-details {
    width: 600px;
    height: 100px;
    margin: 0 auto;
    padding: 22px 0 0 0;
    font-size: 19px; }
    .info .event-details li {
      display: block;
      float: left;
      width: 200px; }

.signup {
  font-size: 19px;
  margin: 40px 0 20px; }
  .signup .subheading {
    font-size: 24px;
    font-weight: 700; }

.save-container {
  height: 278px;
  padding: 80px 0 0 0; }
  .save-container .save-button {
    width: 170px;
    height: 78px;
    line-height: 78px;
    margin: 0 auto;
    background: #ef4358;
    color: #ffffff;
    font-size: 25px;
    cursor: pointer; }

.modal {
  background: #ffffff;
  width: 800px;
  height: 480px;
  border-radius: 40px;
  display: block;
  margin: auto;
  box-shadow: 0px 5px 20px #666666; }
  .modal .saved {
    font-size: 46px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    color: #403b2d; }
  .modal .floral-top {
    margin: 90px 0 10px 0; }
  .modal .floral-bottom {
    margin: 25px 0 0 0; }
  .modal .status {
    width: 400px;
    margin: 0 auto; }
    .modal .status .info-highlight {
      color: #ef4358; }
  .modal hr {
    width: 100px;
    border-top: 2px solid #403b2d;
    margin: 10px auto 14px;
    padding: 0; }

@media (max-width: 641px) {
  .save-container {
    width: 100%;
    height: 78px;
    position: fixed;
    left: 0;
    bottom: 78px; }
    .save-container .save-button {
      width: 100%;
      position: relative; } }

@keyframes save-confirm {
  from {
    border-width: 4px;
    border-style: solid;
    border-color: #00a479; }
  to {
    border-color: transparent; } }

.teams-container {
  max-width: 1000px;
  min-height: 600px; }

.team-container {
  position: relative;
  background: url("./assets/images/team_surround.png");
  width: 425px;
  height: 621px;
  margin: 30px;
  padding: 42px 0 0 0; }
  .team-container .title {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    font-size: 24px;
    font-weight: 700;
    color: #403b2d; }
  .team-container .heading {
    margin-bottom: 54px; }
    .team-container .heading ::placeholder {
      color: #d6d6d6; }
    .team-container .heading input {
      width: 236px;
      height: 63px;
      border: none;
      font-size: 30px;
      text-align: center; }
  .team-container .heading.saved input {
    border-style: solid;
    border-color: #00a479;
    animation-name: save-confirm;
    animation-duration: 4s;
    animation-fill-mode: forwards; }
  .team-container .add-player {
    width: 236px;
    height: 63px;
    line-height: 63px;
    background: #00a479;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    margin: 0 auto;
    display: inline-block; }

.player {
  text-align: left;
  width: 276px;
  position: relative;
  margin: 0 0 4px 0;
  left: 54px; }
  .player .count,
  .player .name,
  .player .delete {
    display: block;
    float: left; }
  .player .name.saved input {
    border-style: solid;
    border-color: #00a479;
    animation-name: save-confirm;
    animation-duration: 4s;
    animation-fill-mode: forwards; }
  .player .count {
    width: 40px;
    line-height: 63px;
    font-family: "BrandonGrotesque-Regular";
    font-size: 22px;
    text-align: right;
    padding: 0 10px 0 0; }
  .player input {
    padding-left: 20px;
    width: 196px;
    height: 63px;
    border: none;
    font-size: 22px; }
  .player ::placeholder {
    color: #d6d6d6; }
  .player .delete {
    width: 40px;
    height: 63px;
    line-height: 63px;
    text-align: center;
    background: #00a479;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer; }
